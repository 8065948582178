import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import Panel from './Panel';
import SidebarMenu from './SidePanel';
import CategoryPanel from './CategoryPanel';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DragControls } from 'three/addons/controls/DragControls.js';
import { TransformControls } from 'three/addons/controls/TransformControls.js';
// import { TransformControls } from 'three/examples/jsm/controls/TransformControls';

import LoadingProgress from './LoadingProgress';
import CANNON from 'cannon';
import CameraControls from "camera-controls";
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';
import { degToRad } from 'three/src/math/MathUtils';
import { FirstPersonControls } from 'three/examples/jsm/controls/FirstPersonControls.js';
import { productData } from "./data";
import ProductPanel from "./ProductPanel";
import TWEEN from 'https://cdn.jsdelivr.net/npm/@tweenjs/tween.js@18.5.0/dist/tween.esm.js';
// import TWEEN from '@tweenjs/tween.js';
// import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
// import * as BufferGeometryUtils from 'three/addons/utils/BufferGeometryUtils.js';
// import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils.js';
import { mergeGeometries } from 'three/examples/jsm/utils/BufferGeometryUtils.js';






const Canvas = () => {

    let rect;
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

    let holdTimeout;
    let isHolding = false;
    const initialStates = useRef({
        objects: [],
        defaultModels: {} // Add your initial model data here
    });
    CameraControls.install({ THREE: THREE });
    let movement = { forward: false, backward: false, left: false, right: false };
    let speed = 2.0;
    let originalMaterial;
    const dragMaterial = new THREE.MeshBasicMaterial({ color: 0xD6A218, transparent: true, opacity: 0.5 });
    const canvasRef = useRef();
    const selectMODE = useRef(false);
    const modelRef = useRef([]);
    const sceneRef = useRef();
    const cameraRef = useRef();
    const cameraPersRef = useRef();
    const cameraOrthoRef = useRef();
    const orbitRef = useRef();
    const modelPathRef = useRef('');
    const firstPersonControl = useRef();
    const activeAddedModel = useRef();
    const dragControlRef = useRef();
    const transformControlRef = useRef();
    const renderer = useRef();
    const firstLoad = useRef(true);
    let isPlacing = false; // Flag to check if we're placing a model
    let modelToPlace = null;
    const selectedObject = useRef(null);
    const addingProduct = useRef(null);
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    let selectedGroup = null;
    let INTERSECTED;
    // Limits for x, y, z
    const minLimit = new THREE.Vector3(-5, 0.1, -5);
    const maxLimit = new THREE.Vector3(5, 5, 5);
    let world;
    const meshes = [];
    const bodies = [];
    let boundingBoxes = [];
    let draggableList = [];
    let sectionGroups = [];
    const [sectionGroupsChange, setSectionGroupsChange] = useState([]);
    const [draggableObjects, setDraggableObjects] = useState([]);
    const [isModelLoaded, setIsModelLoaded] = useState(false);

    let previousPosition = new THREE.Vector3();
    let moveForward = false;
    let moveBackward = false;
    let moveLeft = false;
    let moveRight = false;
    let rotateLeft = false;
    let rotateRight = false;
    let moveSpeed = 0.1;
    let rotateSpeed = 0.02;
    let activeTimeout = null;

    function constrainPosition(object, min, max) {
        object.position.x = THREE.MathUtils.clamp(object.position.x, min.x, max.x);
        object.position.y = THREE.MathUtils.clamp(object.position.y, min.y, max.y);
        object.position.z = THREE.MathUtils.clamp(object.position.z, min.z, max.z);

        console.log('constrainPosition', object.position)
    }


    const init = () => {

        world = new CANNON.World();
        world.gravity.set(0, -9.82, 0)

        world.broadphase = new CANNON.NaiveBroadphase();
        world.solver.iterations = 10; // Increase solver iterations
        world.solver.tolerance = 0.001;

        const width = canvasRef.current.clientWidth;
        const height = canvasRef.current.clientHeight;
        const aspect = width / height;

        cameraPersRef.current = new THREE.PerspectiveCamera(45, aspect, 0.01, 5000);
        cameraRef.current = cameraPersRef.current

        sceneRef.current = new THREE.Scene()
        cameraOrthoRef.current = new THREE.OrthographicCamera(
            - 1 * aspect, 1 * aspect, 1, - 1, 0.1, 1000
        );

        renderer.current = new THREE.WebGLRenderer({ antialias: true, alpha: true });
        renderer.current.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);
        renderer.current.setClearColor('#BBE9FF');
        renderer.current.shadowMap.enabled = true
        renderer.current.shadowMap.type = THREE.PCFSoftShadowMap; // Use soft shadows
        renderer.current.physicallyCorrectLights = true;
        renderer.current.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        renderer.current.toneMapping = THREE.ACESFilmicToneMapping
        renderer.current.toneMappingExposure = 1.5
        canvasRef.current.appendChild(renderer.current.domElement);

        rect = canvasRef.current.getBoundingClientRect();

        const environment = new RoomEnvironment();
        const pmremGenerator = new THREE.PMREMGenerator(renderer.current);
        sceneRef.current.background = new THREE.Color(0xbbbbbb);
        sceneRef.current.environment = pmremGenerator.fromScene(environment).texture;
        // Lighting
        const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
        ambientLight.castShadow = true
        // sceneRef.current.add(ambientLight);

        // const pointLight = new THREE.PointLight(0xffffff, 1);
        // pointLight.position.set(10, 10, 10);
        // scene.add(pointLight);

        const gridHelper = new THREE.GridHelper(50, 50);
        gridHelper.position.set(0, 0, 0)
        // sceneRef.current.add(gridHelper);
        cameraPersRef.current.position.z = 5;
        orbitRef.current = new OrbitControls(cameraRef.current, renderer.current.domElement);
        orbitRef.current.enableDamping = true; // an animation loop is required when either damping or auto-rotation are enabled
        orbitRef.current.dampingFactor = 0.09;
        orbitRef.current.target.set(0, 0, 0)
        orbitRef.current.screenSpacePanning = false;
        // orbitRef.current.maxPolarAngle = Math.PI / 2;




        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        const cube = new THREE.Mesh(geometry, material);
        cube.position.set(2.8871910572052, 0.61859530210495, 2.7134358882904053)
        // sceneRef.current.add(cube)



        let control = new TransformControls(cameraRef.current, renderer.current.domElement);
        // control.addEventListener( 'change', animate );
        control.addEventListener('dragging-changed', function (event) {

            orbitRef.current.enabled = !event.value;

        });


        transformControlRef.current = control;
        transformControlRef.current.getHelper().name = 'CurrentTransform'
        sceneRef.current.add( transformControlRef.current.getHelper())




        const pointLight = new THREE.DirectionalLight(0xffffff, 1);
        pointLight.position.set(10, 20, 10);
        pointLight.castShadow = true;
        // pointLight.shadow.mapSize.width = 4096;  // Higher resolution shadow map
        // pointLight.shadow.mapSize.height = 4096;
        // sceneRef.current.add(pointLight);
        const axesHelper = new THREE.AxesHelper(1000);
        axesHelper.position.set(0, 0, 0)
        // sceneRef.current.add(axesHelper);
        const fillLight = new THREE.HemisphereLight(0xffffff, 0x444444, 0.4);
        // sceneRef.current.add(fillLight);
        const directionalLight = new THREE.DirectionalLight(0xffffff, 1.5);
        // directionalLight.shadow.camera.far = 15;
        // directionalLight.position.set(0, 10, -5);
        // directionalLight.position.set(-4, 6.5, 2.5);
        // directionalLight.shadow.mapSize.set(1024, 1024)
        // directionalLight.scale.set(200,200,200);
        directionalLight.castShadow = true;  // Enable shadows for the light source
        // directionalLight.target.position.set(0, 4, 0)
        directionalLight.position.set(-4, 6, 2); // Experiment with different positions
        directionalLight.target.position.set(0, 0, 0);
        directionalLight.target.updateWorldMatrix();
        directionalLight.shadow.mapSize.set(2048, 2048); // Increase for better quality
        directionalLight.shadow.radius = 4; // Increase to soften shadows
        // // Configure shadow camera to properly enclose the scene
        // directionalLight.shadow.camera.left = -10;
        // directionalLight.shadow.camera.right = 10;
        // directionalLight.shadow.camera.top = 10;
        // directionalLight.shadow.camera.bottom = -10;
        // directionalLight.shadow.camera.near = 0.1;
        // directionalLight.shadow.camera.far = 50;
        // // Small shadow bias to avoid shadow acne
        directionalLight.shadow.bias = -0.004;
        // Adjust the shadow frustum to avoid shadow extension and incorrect placement
        directionalLight.shadow.normalBias = 0.027; // Helps with fixing Peter Panning issue
        sceneRef.current.add(directionalLight);
        const directionalLightCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera)
        // sceneRef.current.add(directionalLightCameraHelper)


        const helper = new THREE.CameraHelper(directionalLight.shadow.camera);
        // sceneRef.current.add(helper);
        let clock = new THREE.Clock()
        const animate = () => {
            console.log('animate')
            requestAnimationFrame(animate);
            TWEEN.update()

            const direction = new THREE.Vector3(); // To calculate direction of movement
            if (moveForward) {
                cameraRef.current.getWorldDirection(direction); // Get direction the camera is facing
                cameraRef.current.position.addScaledVector(direction, moveSpeed); // Move forward in that direction
            }
            if (moveBackward) {
                cameraRef.current.getWorldDirection(direction);
                cameraRef.current.position.addScaledVector(direction, -moveSpeed); // Move backward
            }
            if (moveLeft) {
                cameraRef.current.translateX(-moveSpeed); // Strafe left
            }
            if (moveRight) {
                cameraRef.current.translateX(moveSpeed); // Strafe right
            }
            if (rotateLeft) cameraRef.current.rotation.y += rotateSpeed;  // Rotate left (yaw)
            if (rotateRight) cameraRef.current.rotation.y -= rotateSpeed; // Rotate right (yaw)
            if (orbitRef.current) orbitRef.current.update();
            renderer.current.render(sceneRef.current, cameraRef.current)

        };
        animate();
    }


    function onMouseClick(event) {

        console.log('onMouseClick', selectMODE.current)
        if (selectMODE.current === true) {


            // Calculate mouse position in normalized device coordinates
            mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

            // Set the raycaster
            raycaster.setFromCamera(mouse, cameraRef.current);


            // Normal object selection logic (if not placing a model)
            const intersects = raycaster.intersectObjects(sceneRef.current.children, true);

            if (intersects.length > 0) {
                selectedGroup = intersects[0].object;
                console.log('Intersection found:', selectedGroup);

                if (selectedGroup.isMesh && selectedGroup.material) {
                    const originalColor = selectedGroup.material?.color?.clone(); // Store original color

                    // If another timeout is active, clear it
                    if (activeTimeout) {
                        clearTimeout(activeTimeout);
                        if (selectedObject.current) {
                            selectedObject.current.material?.color?.copy(originalColor); // Reset immediately
                        }
                    }

                    // Change the color to green temporarily
                    selectedGroup.material?.color?.set(0x354E37);
                    selectedObject.current = selectedGroup;
                    if (transformControlRef.current?.enabled === true) {
                        console.log('true transform')
                        transformControlRef.current.attach(selectedObject.current)
                    }

                    // Set a new timeout to revert the color after 100ms
                    activeTimeout = setTimeout(() => {
                        selectedGroup.material?.color?.copy(originalColor);
                        activeTimeout = null;
                    }, 100);
                }
            } else {
                console.log('No object selected');
            }
        }


        else {
            console.log('select mode is off')

        }

    }



    // رویداد حرکت موس
    const onMouseMove = (event) => {

        const rect = canvasRef.current.getBoundingClientRect();

        // Calculate mouse position in normalized device coordinates
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;



        raycaster.setFromCamera(mouse, cameraRef.current); // از موس برای تنظیم raycaster استفاده می‌کنیم
        // const planeZ = 0;
        // const planeZ = 0; // 
        // const plane = new THREE.Plane(new THREE.Vector3(0, 0, 1), planeZ);

        // // محاسبه نقطه تقاطع ray با صفحه
        // const intersectPoint = new THREE.Vector3();
        // raycaster.ray.intersectPlane(plane, intersectPoint);


        if (activeAddedModel.current) {


            activeAddedModel.current.position.x = mouse.x * 8; // Adjust factor for sensitivity on X-axis
            activeAddedModel.current.position.z = -mouse.y * 8; // Adjust factor for sensitivity on Z-axis (inverted to move forward/backward)


            // activeAddedModel.current.position.copy(intersectPoint); // قرار دادن مدل در نقطه تقاطع
            // activeAddedModel.current.position.z = intersectPoint.z; // تنظیم موقعیت Z مدل به نقطه تقاطع
        }


        // const zMappingFactor = 5; // Adjust this factor for sensitivity
        // activeAddedModel.current.position.z = mouse.y + zMappingFactor;
        // تقاطع raycaster با صفحه‌ای فرضی در محور XY
        // const intersects = raycaster.intersectObjects(sceneRef.current.children, true);

        // if (intersects.length > 0 && activeAddedModel.current) {
        //     const intersect = intersects[0];
        //     const currentScale = activeAddedModel.current.scale.clone();

        //     activeAddedModel.current.position.copy(intersect.point); // موقعیت مدل را به موقعیت تقاطع منتقل کنید
        //     activeAddedModel.current.scale.copy(currentScale);
        // }
    };

    function mergeMeshes(meshes, materials) {
        // Array to store geometries
        const geometries = [];
        const mergedGeometry = new THREE.BufferGeometry();
        let materialIndexOffset = 0;



        // Collect the geometries and merge them
        meshes.forEach((mesh, index) => {
            const geometry = mesh.geometry.clone(); // Clone the geometry to avoid modifying the original
            geometry.applyMatrix4(mesh.matrixWorld); // Apply the world matrix to maintain position/rotation/scale
            geometries.push(geometry);

            // Set up a material group for this geometry
            mergedGeometry.addGroup(
                materialIndexOffset,  // Start index
                geometry.index.count,  // Number of indices in this geometry
                index                  // Material index corresponding to this geometry
            );
            materialIndexOffset += geometry.index.count;

        });

        // Merge all geometries into a single BufferGeometry
        const combinedGeometry = mergeGeometries(geometries, true);
        mergedGeometry.copy(combinedGeometry); // Copy the merged geometry

        // Create a new mesh with merged geometry and array of materials
        const mergedMesh = new THREE.Mesh(mergedGeometry, materials);
        mergedMesh.castShadow = true;
        mergedMesh.receiveShadow = true;

        return mergedMesh;




    }


    // Function to replace the model
    const replaceModel = (newModel, preModel) => {


        if (newModel && preModel) {
            let preModelPos = sceneRef.current.getObjectByName(preModel.name).position
            const geometry = new THREE.BoxGeometry(1, 1, 1);
            const material = new THREE.MeshBasicMaterial({ color: 0x00ffff });
            const cube = new THREE.Mesh(geometry, material);
            cube.position.set(preModelPos.x, preModelPos.y, preModelPos.z)
            // sceneRef.current.add(cube)

            sceneRef.current.remove(sceneRef.current.getObjectByName(preModel.name));
            let removePart = sceneRef.current.getObjectByName(preModel.name)
            if (removePart.parent) {
                let parent = removePart.parent;
                parent.remove(removePart);
            }
            newModel.position.set(preModelPos.x, preModelPos.y, preModelPos.z)
            sceneRef.current.add(newModel)
        }


    };




    const onDoubleClick = (event) => {


        console.log('double click addingProduct.current', addingProduct.current)
        console.log('double click draggableObjects', draggableObjects)

        // Calculate mouse position in normalized device coordinates
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

        raycaster.setFromCamera(mouse, cameraRef.current);
        const intersects = raycaster.intersectObjects(sceneRef.current.children, true);

        if (intersects.length > 0) {
            replaceModel(addingProduct.current, intersects[0].object); // Replace the clicked object
        } else {
            console.log("No object clicked.");
        }
    }
    // Event listeners for click-and-hold model replacement
    const onMouseDown = (event) => {

        isHolding = true;

        // Check if the user clicks on the current model using raycasting
        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();
        const rect = canvasRef.current.getBoundingClientRect();

        // Calculate mouse position in normalized device coordinates
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;


        raycaster.setFromCamera(mouse, cameraRef.current);

        const intersects = raycaster.intersectObject(modelRef.current, true);

        console.log('intersects', intersects)
        if (intersects.length > 0) {
            holdTimeout = setTimeout(() => {
                if (isHolding) {
                    replaceModel('./Products/bath/vanity/glb/vanity2_join2.glb', intersects[0].object);
                }
            }, 1000); // Hold for 1 second
        }
    };



    window.addEventListener('mouseup', () => {
        isHolding = false;
        clearTimeout(holdTimeout);
    });



    const addModel = (modelPath) => {


        const loader2 = new GLTFLoader();
        loader2.load(modelPath, (gltf) => {

            gltf.scene.position.set(0, 0, 0)
            // modelRef.current.push(gltf.scene)
            const group = new THREE.Group();
            group.add(gltf.scene);
            // sceneRef.current.add(group);
            // draggableObjects.push(group);
            addingProduct.current = group

            console.log('addModel', addingProduct.current)

            activeAddedModel.current = group
            // document.addEventListener('mousemove', onMouseMove, false);


            // Array to collect meshes for merging
            const meshesToMerge = [];
            const materials = [];


            // Traverse the loaded scene to collect meshes
            group.traverse((child) => {
                if (child.isMesh) {
                    // Push the mesh into the array for merging
                    meshesToMerge.push(child);
                    // Collect the material for each mesh
                    materials.push(child.material);

                    // Handle shadow properties for MeshStandardMaterial
                    if (child.material.isMeshStandardMaterial) {
                        child.castShadow = true;
                        child.receiveShadow = true;
                    }
                }
            });

            // If there are multiple meshes, merge them
            if (meshesToMerge.length > 1) {
                const mergedMesh = mergeMeshes(meshesToMerge, materials);
                console.log('final mesh is >>.', mergedMesh)
                addingProduct.current = mergedMesh
                console.log('addModel merge', addingProduct.current)

                // sceneRef.current.add(mergedMesh); // Add the merged mesh to the scene
                draggableObjects.push(mergedMesh); // Make the merged mesh draggable
                selectedObject.current = mergedMesh
                // transformMode()
            }



            sceneRef.current.traverse((child) => {

                if (child.isMesh) {
                    meshes.push(child);

                    // draggableObjects.push(child);


                    // let test = "LOCK"
                    // if ( ! test.some(el => child.name.includes(el))){
                    //     draggableObjects.push(child);

                    // }

                    if (child.material.isMeshStandardMaterial) {
                        child.castShadow = true
                        child.receiveShadow = true
                    }
                }

            })

            console.log('drag', draggableObjects)

        }, undefined, (error) => {
            console.error('An error happened', error);
        });

    }


    const onTextureScale = async (item) => {

        console.log('texture scale', item)
        // Assume selectedObject is your object with a material and texture
        if (selectedObject.material.map) {
            // Access the texture
            const texture = selectedObject.material.map;

            // Set the texture repeat to desired scale, e.g., scale by 2 in both directions
            texture.repeat.set(item, item);

            // If needed, set the wrapS and wrapT to THREE.RepeatWrapping to ensure repeating works
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;

            // Optionally, update the texture
            texture.needsUpdate = true;
        }
        if (selectedObject.material.aoMap) {
            const texture = selectedObject.material.aoMap;
            texture.repeat.set(item, item);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.needsUpdate = true;
        }
        if (selectedObject.material.normalMap) {
            const texture = selectedObject.material.normalMap;
            texture.repeat.set(item, item);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.needsUpdate = true;
        }
        if (selectedObject.material.roughnessMap) {
            const texture = selectedObject.material.roughnessMap;
            texture.repeat.set(item, item);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.needsUpdate = true;
        }
        if (selectedObject.material.displacementMap) {
            const texture = selectedObject.material.displacementMap;
            texture.repeat.set(item, item);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.needsUpdate = true;
        }
        if (selectedObject.material.metalnessMap) {
            const texture = selectedObject.material.metalnessMap;
            texture.repeat.set(item, item);
            texture.wrapS = THREE.RepeatWrapping;
            texture.wrapT = THREE.RepeatWrapping;
            texture.needsUpdate = true;
        }




    }

    const onTextureRepeat = async (item) => {
        console.log('texture repeat ....', item)
        onTextureScale(item)



    }
    const onTexturePosition = async (item) => {

        console.log('texture Position', item)
        // Assume selectedObject is your object with a material and texture
        if (selectedObject.material.map) {
            // Access the texture
            const texture = selectedObject.material.map;
            texture.offset.set(item.x, item.y);
            // texture.rotation =selectedObject.material.map.rotation

            // Move texture 20% on X-axis and 30% on Y-axis
            // Optionally, update the texture
            texture.needsUpdate = true;
        }
        if (selectedObject.material.aoMap) {
            const texture = selectedObject.material.aoMap;
            texture.offset.set(item.x, item.y); // Move texture 20% on X-axis and 30% on Y-axis
            texture.needsUpdate = true;
        }
        if (selectedObject.material.normalMap) {
            const texture = selectedObject.material.normalMap;
            texture.offset.set(item.x, item.y); // Move texture 20% on X-axis and 30% on Y-axis
            texture.needsUpdate = true;
        }
        if (selectedObject.material.roughnessMap) {
            const texture = selectedObject.material.roughnessMap;
            texture.offset.set(item.x, item.y); // Move texture 20% on X-axis and 30% on Y-axis
            texture.needsUpdate = true;
        }
        if (selectedObject.material.displacementMap) {
            const texture = selectedObject.material.displacementMap;
            texture.offset.set(item.x, item.y); // Move texture 20% on X-axis and 30% on Y-axis
            texture.needsUpdate = true;
        }
        if (selectedObject.material.metalnessMap) {
            const texture = selectedObject.material.metalnessMap;
            texture.offset.set(item.x, item.y); // Move texture 20% on X-axis and 30% on Y-axis
            texture.needsUpdate = true;
        }




    }
    const onTextureRotate = async (item) => {

        console.log('texture rotate', item)
        // Assume selectedObject is your object with a material and texture
        if (selectedObject.material.map) {
            const texture = selectedObject.material.map;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }
        if (selectedObject.material.aoMap) {
            const texture = selectedObject.material.aoMap;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }
        if (selectedObject.material.normalMap) {
            const texture = selectedObject.material.normalMap;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }
        if (selectedObject.material.roughnessMap) {
            const texture = selectedObject.material.roughnessMap;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }
        if (selectedObject.material.displacementMap) {
            const texture = selectedObject.material.displacementMap;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }
        if (selectedObject.material.metalnessMap) {
            const texture = selectedObject.material.metalnessMap;
            texture.rotation = item * (Math.PI / 180); // Example: rotate by 45 degrees
            texture.center.set(0.5, 0.5);
            texture.needsUpdate = true;
        }




    }
    const onImageSelect = async (item) => {

        console.log('selectedObject onImageSelect', selectedObject.current)

        const floor1 = new THREE.Mesh(
            new THREE.PlaneGeometry(3.23, 7.45),
        );
        floor1.position.set(7, 0, 0);
        floor1.rotation.set(degToRad(90), 0, 0);
        sceneRef.current.add(floor1);
        const meshSize1 = new THREE.Vector3();
        floor1.geometry.computeBoundingBox();
        floor1.geometry.boundingBox.getSize(meshSize1);
        const textureLoader = new THREE.TextureLoader();

        const textureـbaseColor1 = await textureLoader.loadAsync(item.baseColor);
        textureـbaseColor1.wrapS = THREE.RepeatWrapping;
        textureـbaseColor1.wrapT = THREE.RepeatWrapping;

        let width = textureـbaseColor1.image.width
        let height = textureـbaseColor1.image.height;


        let aspecRatio = width / height;

        const repeatX = meshSize1.x / (width / aspecRatio * 0.0002645833)
        let repeatY;
        let otherMax = Math.max(meshSize1.y, meshSize1.z)
        if (otherMax === meshSize1.y) {
            repeatY = meshSize1.y / (height / aspecRatio * 0.0002645833);

        }
        if (otherMax === meshSize1.z) {
            repeatY = meshSize1.z / (height / aspecRatio * 0.0002645833);
        }

        textureـbaseColor1.repeat.set(repeatX, repeatY);
        textureـbaseColor1.colorSpace = THREE.SRGBColorSpace;


        floor1.material = new THREE.MeshStandardMaterial({
            side: THREE.DoubleSide,
            map: textureـbaseColor1
        })

        // textureـbaseColor.anisotropy = renderer.current.capabilities.getMaxAnisotropy();
        // Calculate the mesh size along the desired axes

        //Main Code

        const meshSize = new THREE.Vector3();
        selectedObject.current.geometry.computeBoundingBox();
        selectedObject.current.geometry.boundingBox.getSize(meshSize);

        console.log('main meshSize', meshSize)

        const scaleX = selectedObject.current.scale.x;
        const scaleY = selectedObject.current.scale.y;
        const scaleZ = selectedObject.current.scale.z;
        const textureLoader2 = new THREE.TextureLoader();
        const textureـbaseColor2 = await textureLoader2.loadAsync(item.baseColor);
        textureـbaseColor2.wrapS = THREE.RepeatWrapping;
        textureـbaseColor2.wrapT = THREE.RepeatWrapping;
        let width2 = textureـbaseColor2.image.width
        let height2 = textureـbaseColor2.image.height;
        console.log(' Main width2 img and height2', width2, height2)

        let aspecRatio2 = width2 / height2;

        let meshSizes = [meshSize.x, meshSize.y, meshSize.z];

        let mainRepeat;

        if (width2 === height2) {
            //moraba 

            if (meshSize.y > 0.01) {
                //wall
                let maxWall = Math.max(meshSize.x, meshSize.z)
                console.log('this is maxWall', maxWall)
                console.log('width image meter', (width2 * 0.0002645833))

                // let newRepeatWall = maxWall / (width2 / aspecRatio2 * 0.0002645833);
                let newRepeatWall = maxWall / (width2 * 0.0002645833);
                console.log('newRepeatWall :', newRepeatWall);

                textureـbaseColor2.repeat.set(newRepeatWall, newRepeatWall);
                mainRepeat = newRepeatWall


            } else {

                console.log('this is floor')
                // Filter out small or zero values
                let filteredSizes = meshSizes.filter(size => size > 0.01);
                // Find the minimum among the remaining sizes
                let ARZ = Math.min(...filteredSizes);

                console.log('ARZ :', ARZ);

                // let newRepeat = ARZ / (width2 / aspecRatio2 * 0.0002645833);
                let newRepeat = ARZ / (width2 * 0.0002645833);

                console.log('newRepeat :', newRepeat);
                textureـbaseColor2.repeat.set(newRepeat, newRepeat);
                mainRepeat = newRepeat

            }

        }


        console.log('main image size', width2, height2)

        let repeatY2;
        // let mainMin = Math.min(meshSize.x, meshSize.y, meshSize.z)




        textureـbaseColor2.colorSpace = THREE.SRGBColorSpace;

        let metalnessMap, textureـaoMap, textureـnormalMap, roughnessMap, displacementMap
        if (item.aomap.length > 0) {
            textureـaoMap = await textureLoader.loadAsync(item.aomap);
            textureـaoMap.wrapS = THREE.RepeatWrapping;
            textureـaoMap.wrapT = THREE.RepeatWrapping;
            let aspecRatio_aop = textureـaoMap.image.width / textureـaoMap.image.height;
            let repeatY_aomap;
            const repeatX_aomap = meshSize.x /
                (textureـaoMap.image.width / aspecRatio_aop * 0.0002645833);

            let otherMax2 = Math.max(meshSize.y, meshSize.z)
            if (otherMax2 === meshSize.y) {
                repeatY_aomap = meshSize.y / (textureـaoMap.image.height / aspecRatio_aop * 0.0002645833);

            }

            if (otherMax2 === meshSize.z) {
                repeatY_aomap = meshSize.z / (textureـaoMap.image.height / aspecRatio_aop * 0.0002645833);
            }
            // textureـaoMap.repeat.set(repeatX_aomap, repeatY_aomap);
            textureـaoMap.repeat.set(mainRepeat, mainRepeat);
            textureـaoMap.colorSpace = THREE.SRGBColorSpace;

        }

        if (item.normalmap.length > 0) {
            textureـnormalMap = await textureLoader.loadAsync(item.normalmap);
            textureـnormalMap.wrapS = THREE.RepeatWrapping;
            textureـnormalMap.wrapT = THREE.RepeatWrapping;
            let aspecRatio_normalMap = textureـnormalMap.image.width / textureـnormalMap.image.height;
            let repeatY_normalmap;
            const repeatX_normalmap = meshSize.x /
                (textureـnormalMap.image.width / aspecRatio_normalMap * 0.0002645833)

            let otherMax2 = Math.max(meshSize.y, meshSize.z)

            if (otherMax2 === meshSize.y) {
                repeatY_normalmap = meshSize.y / (textureـnormalMap.image.height / aspecRatio_normalMap * 0.0002645833);

            }
            if (otherMax2 === meshSize.z) {
                repeatY_normalmap = meshSize.z / (textureـnormalMap.image.height / aspecRatio_normalMap * 0.0002645833);
            }


            // textureـnormalMap.repeat.set(repeatX_normalmap, repeatY_normalmap);
            textureـnormalMap.repeat.set(mainRepeat, mainRepeat);
            textureـnormalMap.colorSpace = THREE.SRGBColorSpace;
        }


        if (item.roughness.length > 0) {

            roughnessMap = await textureLoader.loadAsync(item.roughness);
            roughnessMap.wrapS = THREE.RepeatWrapping;
            roughnessMap.wrapT = THREE.RepeatWrapping;
            let aspecRatio_roughnessMap = roughnessMap.image.width / roughnessMap.image.height;

            let repeatY_roughnessMap;

            const repeatX_roughnessMap = meshSize.x /
                (roughnessMap.image.width / aspecRatio_roughnessMap * 0.0002645833)


            let otherMax2 = Math.max(meshSize.y, meshSize.z)

            if (otherMax2 === meshSize.y) {
                repeatY_roughnessMap = meshSize.y / (roughnessMap.image.height / aspecRatio_roughnessMap * 0.0002645833);

            }
            if (otherMax2 === meshSize.z) {
                repeatY_roughnessMap = meshSize.z / (roughnessMap.image.height / aspecRatio_roughnessMap * 0.0002645833);
            }

            // roughnessMap.repeat.set(repeatX_roughnessMap, repeatY_roughnessMap);
            roughnessMap.repeat.set(mainRepeat, mainRepeat);
            roughnessMap.colorSpace = THREE.SRGBColorSpace;
        }

        if (item.displacement.length > 0) {
            displacementMap = await textureLoader.loadAsync(item.displacement);
            displacementMap.wrapS = THREE.RepeatWrapping;
            displacementMap.wrapT = THREE.RepeatWrapping;
            let aspecRatio_displacementMap = displacementMap.image.width / displacementMap.image.height;
            let repeatY_displacementMap;

            const repeatX_displacementMap = meshSize.x /
                (displacementMap.image.width / aspecRatio_displacementMap * 0.0002645833)


            let otherMax2 = Math.max(meshSize.y, meshSize.z)

            if (otherMax2 === meshSize.y) {
                repeatY_displacementMap = meshSize.y / (displacementMap.image.height / aspecRatio_displacementMap * 0.0002645833);

            }
            if (otherMax2 === meshSize.z) {
                repeatY_displacementMap = meshSize.z / (displacementMap.image.height / aspecRatio_displacementMap * 0.0002645833);
            }

            // displacementMap.repeat.set(repeatX_displacementMap, repeatY_displacementMap);
            displacementMap.repeat.set(mainRepeat, mainRepeat);
            displacementMap.colorSpace = THREE.SRGBColorSpace;
        }

        if (item.metallic.length > 0) {
            metalnessMap = await textureLoader.loadAsync(item.metallic);
            metalnessMap.wrapS = THREE.RepeatWrapping;
            metalnessMap.wrapT = THREE.RepeatWrapping;
            let aspecRatio_metalnessMap = metalnessMap.image.width / metalnessMap.image.height;


            let repeatY_metalnessMap;

            const repeatX_metalnessMap = meshSize.x /
                (metalnessMap.image.width / aspecRatio_metalnessMap * 0.0002645833)

            let otherMax2 = Math.max(meshSize.y, meshSize.z)

            if (otherMax2 === meshSize.y) {
                repeatY_metalnessMap = meshSize.y / (metalnessMap.image.height / aspecRatio_metalnessMap * 0.0002645833);

            }
            if (otherMax2 === meshSize.z) {
                repeatY_metalnessMap = meshSize.z / (metalnessMap.image.height / aspecRatio_metalnessMap * 0.0002645833);
            }

            // metalnessMap.repeat.set(repeatX_metalnessMap, repeatY_metalnessMap);
            metalnessMap.repeat.set(mainRepeat, mainRepeat);
            metalnessMap.colorSpace = THREE.SRGBColorSpace;

        }

        const material = new THREE.MeshStandardMaterial({
            map: textureـbaseColor2,
            aoMap: textureـaoMap,
            normalMap: textureـnormalMap,
            roughnessMap: roughnessMap,


            displacementMap: displacementMap,
            displacementScale: 0.1,
            metalnessMap: metalnessMap,
            // envMapIntensity: 0.8,
            //  roughness: 0.5, // Base material roughness
            // metalness: 0.5 
        });

        selectedObject.current.material = material;

        // Update geometry for correct aoMap use, if needed
        if (textureـaoMap) {
            selectedObject.current.geometry.attributes.uv2 = selectedObject.current.geometry.attributes.uv;
        }

    };





    const selectMode = () => {

        console.log('selectMode',sceneRef.current.children)
        selectMODE.current = true

        orbitRef.current.enabled = true


        // transformControlRef.current.reset()
        if (transformControlRef.current) {
            transformControlRef.current.detach();
            sceneRef.current.remove(transformControlRef.current);
            let removePart = sceneRef.current.getObjectByName('CurrentTransform')
            console.log('removePart',removePart)
            if (removePart.parent) {
                let parent = removePart.parent;
                parent.remove(removePart);
            }

            transformControlRef.current = null;


            // transformControlRef.current.enabled = false
            // transformControlRef.current.visible = false
            // transformControlRef.current.dispose()
            // transformControlRef.current.children.forEach(child => {
            //     // if (child.name === 'TransformControlsPlane' || child.name.startsWith('TransformControlsLine')) {
            //         sceneRef.current.remove(child);  // or child.visible = false if you want to hide it
            //     // }
            // });
            const transformControlsPlane = sceneRef.current.getObjectByName('TransformControlsPlane');
            if (transformControlsPlane) {
                sceneRef.current.remove(transformControlsPlane);
            }


        }
console.log(' sceneRef.current',sceneRef.current.children)


        if (dragControlRef.current) {
            dragControlRef.current.enabled = false;
            dragControlRef.current.transformGroup = false;
            dragControlRef.current.removeEventListener('dragstart', handleDragStart);
            dragControlRef.current.removeEventListener('drag', handleDrag);
            dragControlRef.current.removeEventListener('dragend', handleDragEnd);
        }

        // Cleanup keydown listener when switching to orbit mode
        window.removeEventListener('keydown', handleKeyDown);

    }

    const orbitMode = () => {

        console.log('orbitMode', transformControlRef.current)

        orbitRef.current.enabled = true
        transformControlRef.current.detach();
        transformControlRef.current.enabled = false
        if (dragControlRef.current) {
            dragControlRef.current.enabled = false;
            // dragControlRef.current = ""
        }

        // Cleanup keydown listener when switching to orbit mode
        window.removeEventListener('keydown', handleKeyDown);



    }
    const deleteSelected = () => {

        console.log('delete', selectedObject.current)
        if (selectedObject.current) {
            transformControlRef.current.detach();
            sceneRef.current.remove(sceneRef.current.getObjectByName(selectedObject.current.name));

            let removePart = sceneRef.current.getObjectByName(selectedObject.current.name)
            if (removePart.parent) {
                let parent = removePart.parent;
                parent.remove(removePart);
            }

            selectedObject.current = null;

        }
    }


    const transformMode = () => {

        console.log('transformMode', selectedObject.current)
        selectMODE.current = false

        if (dragControlRef.current) dragControlRef.current.enabled = false

        let control = new TransformControls(cameraRef.current, renderer.current.domElement);
        // control.addEventListener( 'change', animate );
        control.addEventListener('dragging-changed', function (event) {

            orbitRef.current.enabled = !event.value;

        });


        transformControlRef.current = control;
        transformControlRef.current.getHelper().name = 'CurrentTransform'
        transformControlRef.current.reset()
        transformControlRef.current.enabled = true

        if (selectedObject.current) {
            if (selectedObject.current.name.includes("LOCK") === false) {


                transformControlRef.current.attach(selectedObject.current)
                sceneRef.current.add(transformControlRef.current.getHelper())


                transformControlRef.current.addEventListener('mouseDown', () => {
                    orbitRef.current.enabled = false;
                });

                transformControlRef.current.addEventListener('mouseUp', () => {
                    orbitRef.current.enabled = true;

                });

                transformControlRef.current.addEventListener('objectChange', () => {
                    constrainPosition(selectedObject.current, minLimit, maxLimit);
                });

                window.addEventListener('keydown', handleKeyDown);

            }
            else {
                console.log('you ant to change a lock object')
            }
        }


    }


    // Keydown handler defined separately to avoid issues with multiple listeners
    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'r': // Press 'r' to switch to rotation mode
                transformControlRef.current.setMode('rotate');
                break;
            // case 's': // Press 's' to switch to scale mode
            //     transformControlRef.current.setMode('scale');
            //     break;
            case 't': // Press 't' to switch to translate mode
                transformControlRef.current.setMode('translate');
                break;
        }
    };

    // Check for collisions using Box3
    function checkCollisions(draggedObject) {
        for (let i = 0; i < draggableObjects.length; i++) {
            const object = draggableObjects[i];

            // Skip checking collision with itself
            if (object === draggedObject || !object.boundingBox) continue;


            // If bounding boxes intersect, there's a collision
            if (draggedObject.boundingBox.intersectsBox(object.boundingBox)) {
                return true;  // Collision detected
            }
        }
        return false;  // No collision
    }

    const handleDragStart = (event) => {
        console.log('dragstart name >>>', event.object.name);
        orbitRef.current.enabled = false;

        originalMaterial = event.object.material;
        event.object.material = dragMaterial;
        const draggedObject = event.object;
        selectedObject.current = draggedObject
        previousPosition.copy(draggedObject.position);
    };

    const handleDrag = (event) => {
        orbitRef.current.enabled = false;
    };

    const handleDragEnd = (event) => {
        console.log('Drag ended: ', event.object);
        // orbitRef.current.enabled = true;
        event.object.material = originalMaterial;
    };


    const dragMode = () => {

        orbitRef.current.enabled = false

        console.log('dragmode', draggableObjects)
        selectMODE.current = false

        transformControlRef.current.reset()
        transformControlRef.current.enabled = false
        transformControlRef.current.detach();

        if (dragControlRef.current) dragControlRef.current.enabled = true;
        // dragControlRef.current.transformGroup = true;
        // dragControlRef.current.transformRoot=true



    }

    const onCheckedItems = (item) => {

        modelRef.current.traverse((child) => {
            // Check if the child is a mesh and if its name is in the Set
            if (child.isMesh) {
                if (item.some(el => child.name.includes(el))) {
                    console.log('child name true', child.name)
                    child.visible = true; // Hide the object
                } else {
                    console.log('child name false', child.name)
                    child.visible = false; // Ensure it is visible
                }
            }
        });


    }


    function moveCameraToGroup(group) {
        // Calculate the bounding box of the group
        const box = new THREE.Box3().setFromObject(group);

        // Get the center of the bounding box
        const center = new THREE.Vector3();
        box.getCenter(center);

        console.log('center', group.name, center)
        // Position the camera above the group
        const distanceAbove = box.getSize(new THREE.Vector3()).y;
        const targetPosition = center.clone().add(new THREE.Vector3(0, distanceAbove * 3, 0)); // Adjust distance as needed

        // Move the camera to this new position using Tween.js
        const cameraStart = cameraRef.current.position.clone();

        const geometry = new THREE.BoxGeometry(1, 1, 1);
        const material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        const cube = new THREE.Mesh(geometry, material);
        cube.position.set(cameraStart.x, cameraStart.y, cameraStart.z)
        // sceneRef.current.add(cube);


        // console.log('currect cam',cameraStart)
        console.log('targetPosition', group.name, targetPosition)

        const geometry2 = new THREE.BoxGeometry(1, 1, 1);
        const material2 = new THREE.MeshBasicMaterial({ color: 0x00ffff });
        const cube2 = new THREE.Mesh(geometry2, material2);
        cube2.position.set(targetPosition.x, targetPosition.y, targetPosition.z)
        // sceneRef.current.add(cube2);


        new TWEEN.Tween(cameraStart)
            .to({ x: targetPosition.x, y: targetPosition.y, z: targetPosition.z }, 1000)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onUpdate(() => {
                cameraRef.current.position.copy(cameraStart);
                cameraRef.current.lookAt(center);
                orbitRef.current.target.copy(center);

                // orbitRef.current.target.set(center)
            })
            .start();

        cameraRef.current.rotation.set(-Math.PI / 2, 0, 0);



    }


    const onGroupSelect = (item) => {
        console.log('group click selected >>>', item)

        let selectedGroup;

        // Make the material look more glass-like for the hidden objects
        const glassMaterial = new THREE.MeshPhysicalMaterial({
            color: 0x999999,
            metalness: 0.1,
            roughness: 0.1,
            transparent: true,
            opacity: 0.5,
            transmission: 0.9,  // Controls how much light passes through
            ior: 1.5,  // Index of refraction, gives the glass effect
            reflectivity: 0.2,
        });

        let i = 0
        sceneRef.current.children.map(el => {

            console.log('child scene', el)

            if (el.type === "Group") {
                el.children.map(gr => {
                    if (gr.name === item.name) {
                        // console.log('groups to select in scene',gr)
                        selectedGroup = gr;

                        gr.traverse((child) => {

                            if (child.type === "Group") {

                                child.traverse((subchild) => {
                                    if (subchild.isMesh) {
                                        // console.log('name blue',subchild.name,i++)
                                        // Reset the material of the active group to its original state
                                        // subchild.material.color.set(0x0099CC)
                                        // subchild.material.transparent = false;
                                        // subchild.material.opacity = 1;

                                        subchild.visible = true

                                    }
                                })
                            }
                            if (child.isMesh) {
                                // console.log('name blue', child.name, i++)

                                // Reset the material of the active group to its original state
                                // child.material.color.set(0x0099CC)
                                // child.material.transparent = false;
                                // child.material.opacity = 1;
                                child.visible = true

                            }
                        });
                    }
                    else {
                        // For all other groups, make their materials transparent or hidden
                        gr.traverse((child) => {
                            if (child.isMesh) {
                                // Make the material transparent like glass
                                // child.material = glassMaterial;
                                // child.material.transparent = true;
                                child.visible = false
                                // child.material.opacity = 0.1;  // Adjust this value for more/less transparency
                            }
                        });
                    }
                })
            }

        })

        if (selectedGroup) {
            moveCameraToGroup(selectedGroup);
        }


    }


    const loadModel = (modelPath) => {

        setLoadingProgress(0);
        setIsLoading(true); // Start loading
        let estimatedProgress = 0;
        let progressInterval;


        modelPathRef.current = modelPath
        const loader = new GLTFLoader();
        loader.load(modelPath, (gltf) => {

            clearInterval(progressInterval);

            if (modelRef.current) {
                sceneRef.current.remove(modelRef.current);
            }


            console.log('data gltf', gltf)

            modelRef.current = gltf.scene
            // gltf.scene.rotation.x = Math.PI / 2;
            sceneRef.current.add(gltf.scene);

            initialStates.current.defaultModels = gltf.scene
            initialStates.current.objects = [];


            gltf.scene.children.forEach((item) => {


                //باید داخل گروه هارا ذخیره و سپس اپدیت کنیم برای reset scene
                // if (item.type === "Group"){


                //     initialStates.current.objects.push({
                //         object: item,
                //         position: item.position.clone(),
                //         rotation: item.rotation.clone(),
                //         scale: item.scale.clone(),
                //         // material: item.material.clone() // Assuming material is not linked to other objects
                //     });

                // }


            })


            gltf.scene.traverse((child) => {

                if (child.isMesh) {

                    if (child.name.includes("LOCK") === false) {
                        draggableList.push(child);

                    }
                    if (child.material.isMeshStandardMaterial) {
                        child.castShadow = true
                        child.receiveShadow = true
                    }
                }

            })





            console.log('draggableObjects model', draggableList)
            setDraggableObjects(draggableList)



            setIsModelLoaded(true)
            setLoadingProgress(100);
            setIsLoading(false); // Loading complete


        },     (xhr) => {

            if (xhr.total > 0) {
                // Use actual progress if total size is available
                const percentComplete = (xhr.loaded / xhr.total) * 100;
                setLoadingProgress(Math.round(percentComplete));
            } else if (!progressInterval) {
                // Start fallback progress when xhr.total is zero and no interval is set
                progressInterval = setInterval(() => {
                    estimatedProgress = Math.min(estimatedProgress + 5, 95);
                    setLoadingProgress(estimatedProgress);
                }, 200); // Increase progress every 200ms
            }



            // if (xhr.total > 0) {
            //     // Calculate progress normally if total size is available
            //     const percentComplete = (xhr.loaded / xhr.total) * 100;
            //     setLoadingProgress(Math.round(percentComplete));
            // } else {
            //     // Fallback approach: Incrementally estimate progress
            //     estimatedProgress = Math.min(estimatedProgress + 5, 95); // Prevent going beyond 95%
            //     setLoadingProgress(estimatedProgress);
            // }


            // Calculate and log the loading progress percentage
            // console.log(`Loaded: ${xhr.loaded} bytes`);
            // console.log(`Total: ${xhr.total} bytes`);
            // const percentComplete = (xhr.loaded / xhr.total) * 100;
            // console.log(`Model loading: ${Math.round(percentComplete)}%`);
            // // You can also call a function to update a progress bar UI component here
            // setLoadingProgress(Math.round(percentComplete));

        }, (error) => {
            console.error('An error happened', error);
            setIsLoading(false); // Loading complete
        });

        if (!dragControlRef.current) {
            dragControlRef.current = new DragControls(draggableList, cameraRef.current, renderer.current.domElement);
            dragControlRef.current.addEventListener('dragstart', handleDragStart);
            dragControlRef.current.addEventListener('drag', handleDrag);
            dragControlRef.current.addEventListener('dragend', handleDragEnd);
            dragControlRef.current.enabled = false
        }

        // }
    };

    const changeTexture = (textureURL) => {
        const textureloader = new THREE.TextureLoader();
        let map1 = textureloader.load(textureURL.diff);
        // map1.rotation=10
        let normalMap1 = textureloader.load(textureURL.norgl);
        let height = textureloader.load(textureURL.height);
        let roughnessmp = textureloader.load(textureURL.roughness);
        // let aoMap1 = textureloader.load(textureURL.aomap);
        map1.colorSpace = THREE.SRGBColorSpace
        height.colorSpace = THREE.SRGBColorSpace
        roughnessmp.colorSpace = THREE.SRGBColorSpace
        // aoMap1.colorSpace = THREE.SRGBColorSpace
        // let obj = sceneRef.current.getObjectByName('Object_5002')
        // obj.material.map = map1

        normalMap1.wrapS = THREE.RepeatWrapping
        normalMap1.wrapT = THREE.RepeatWrapping

        roughnessmp.wrapS = THREE.RepeatWrapping
        roughnessmp.wrapT = THREE.RepeatWrapping

        height.wrapS = THREE.RepeatWrapping
        height.wrapT = THREE.RepeatWrapping

        map1.wrapS = THREE.RepeatWrapping
        map1.wrapT = THREE.RepeatWrapping

        normalMap1.repeat.x = 8
        normalMap1.repeat.y = 8

        map1.repeat.x = 8
        map1.repeat.y = 8

        height.repeat.x = 8
        height.repeat.y = 8

        roughnessmp.repeat.x = 8
        roughnessmp.repeat.y = 8


        selectedObject.material = new THREE.MeshStandardMaterial({
            map: map1,
            normalMap: normalMap1,
            // normalScale: new THREE.Vector2(1, 0.2),
            // aoMap: aoMap1,
            color: 0x6ac0ff,
            // aoMapIntensity: 1,
            // roughnessMap: aoMap1,
            // metalnessMap: aoMap1,
            displacementMap: height,
            displacementScale: 0.005
        })
        const floor = new THREE.Mesh(
            new THREE.PlaneGeometry(8, 8),
            new THREE.MeshStandardMaterial({
                map: map1,
                color: 0x6ac0ff,
                normalMap: normalMap1,
                roughnessMap: roughnessmp,
                displacementMap: height,
                displacementScale: 0.005
            })
        )
        floor.position.set(1, 5, -5)
        // sceneRef.current.add(floor)


    }



    const resetScene = () => {


        // Remove all objects from the scene
        sceneRef.current.clear();
        loadModel(modelPathRef.current)

        // console.log('modelRef.current',modelRef.current)
        // sceneRef.current.add(modelRef.current)



    }

    const resetCamera = () => {
        const initialPosition = new THREE.Vector3(0, 5, 10); // Initial camera position
        const initialTarget = new THREE.Vector3(0, 0, 0); // Initial target position

        // Reset camera position
        cameraRef.current.position.copy(initialPosition);
        cameraRef.current.lookAt(initialTarget);

        // Reset OrbitControls target
        orbitRef.current.target.copy(initialTarget);
        orbitRef.current.update();
    };


    function updateCameraMovement(delta) {
        // Camera forward and right vectors
        const forward = new THREE.Vector3();
        const right = new THREE.Vector3();
        cameraRef.current.getWorldDirection(forward);
        forward.y = 0; // Prevent moving up or down
        forward.normalize();
        right.crossVectors(cameraRef.current.up, forward).normalize();

        // Movement logic
        // if (movement.forward) cameraRef.current.controls.moveTo(cameraRef.current.position.x + forward.x * delta * speed, cameraRef.current.position.y, cameraRef.current.position.z + forward.z * delta * speed);
        // if (movement.backward) cameraRef.current.controls.moveTo(cameraRef.current.position.x - forward.x * delta * speed, cameraRef.current.position.y, cameraRef.current.position.z - forward.z * delta * speed);
        // if (movement.left) cameraRef.current.controls.moveTo(cameraRef.current.position.x - right.x * delta * speed, cameraRef.current.position.y, cameraRef.current.position.z - right.z * delta * speed);
        // if (movement.right) cameraRef.current.controls.moveTo(cameraRef.current.position.x + right.x * delta * speed, cameraRef.current.position.y, cameraRef.current.position.z + right.z * delta * speed);

        if (movement.forward) cameraRef.current.controls.forward(0.25, true)
        if (movement.backward) cameraRef.current.controls.truck(-0.25, 0, true)
        // if (movement.left)   cameraRef.current.controls.distance--
        if (movement.left) cameraRef.current.controls.rotate(-0.1, 0, true)
        if (movement.right) cameraRef.current.controls.rotate(+0.1, 0, true)




    }

    const firstPersonMode = () => {

        // cameraMode('orthographic')
        console.log('cameraRef.current', cameraRef.current)
        firstPersonControl.current = new FirstPersonControls(cameraRef.current, renderer.current.domElement);
        firstPersonControl.current.movementSpeed = 5;
        firstPersonControl.current.lookSpeed = 0.01;
        firstPersonControl.current.lookVertical = false; // Allow looking up/down



        // Keydown event for movement and rotation
        document.addEventListener('keydown', (event) => {
            switch (event.code) {
                case 'ArrowLeft':
                    rotateLeft = true;
                    break;
                case 'ArrowRight':
                    rotateRight = true;
                    break;
                case 'KeyW':
                    moveForward = true;
                    break;
                case 'KeyS':
                    moveBackward = true;
                    break;
                case 'KeyA':
                    moveLeft = true;
                    break;
                case 'KeyD':
                    moveRight = true;
                    break;
            }
        });

        // Keyup event to stop movement or rotation
        document.addEventListener('keyup', (event) => {
            switch (event.code) {
                case 'ArrowLeft':
                    rotateLeft = false;
                    break;
                case 'ArrowRight':
                    rotateRight = false;
                    break;
                case 'KeyW':
                    moveForward = false;
                    break;
                case 'KeyS':
                    moveBackward = false;
                    break;
                case 'KeyA':
                    moveLeft = false;
                    break;
                case 'KeyD':
                    moveRight = false;
                    break;
            }
        });

        // const controls1 = new CameraControls(cameraRef.current, renderer.current.domElement);
        // controls1.dollyToCursor=true
        // cameraRef.current.controls=controls1

        // cameraRef.current.controls.setLookAt(5, 5, 5, 0, 0, 0);



        // Movement control variables
        // const speed = 2.0;
        // const movement = { forward: false, backward: false, left: false, right: false };

        // Add event listeners for movement keys (WASD)
        // window.addEventListener('keydown', (event) => {
        //     switch (event.code) {
        //         case 'KeyW': movement.forward = true; break;
        //         case 'KeyS': movement.backward = true; break;
        //         case 'KeyA': movement.left = true; break;
        //         case 'KeyD': movement.right = true; break;
        //     }
        // });

        // window.addEventListener('keyup', (event) => {
        //     switch (event.code) {
        //         case 'KeyW': movement.forward = false; break;
        //         case 'KeyS': movement.backward = false; break;
        //         case 'KeyA': movement.left = false; break;
        //         case 'KeyD': movement.right = false; break;
        //     }
        // });


        // cameraRef.current.controls.setLookAt(5, 5, 5, 0, 0, 0);

        // function onKeyDown(event) {
        //     switch (event.keyCode) {
        //         case 37:
        //         //     const newTargetPosition = new THREE.Vector3();
        //         //     cameraRef.current.controls.distance--
        //         //     cameraRef.current.controls.getPosition(newTargetPosition)
        //             //  camera.controls.minDistance = 1;
        //             //  camera.controls.maxDistance = 1;
        //             //  camera.controls.distance = 1;
        //             // cameraRef.current.controls.setLookAt(newTargetPosition.x--, newTargetPosition.y, newTargetPosition.z, 0, 0, 0);
        //             // camera.controls.moveTo(newTargetPosition.x,newTargetPosition.y,newTargetPosition.z)
        //             // cameraRef.current.controls.truckSpeed = 20;
        //             // cameraRef.current.controls.mouseButtons.wheel = CameraControls.ACTION.DOLLY;
        //             // cameraRef.current.controls.touches.two = CameraControls.ACTION.TOUCH_ZOOM_TRUCK;
        //             //   camera.controls.right(0.25,true)
        //             cameraRef.current.controls.forward(0.25, true)
        //             console.log('left', cameraRef.current.controls)
        //             break;
        //         // case 38:
        //         //     //ok
        //         //     console.log('up')
        //         //     camera.controls.forward(0.25, true)
        //         //     break;
        //         // case 39:
        //         //     console.log('right')

        //         //     break;
        //         // case 40:
        //         //     //ok
        //         //     console.log('down')
        //         //     camera.controls.forward(-0.25, true)
        //         //     break;
        //         // default:
        //         //     break;
        //     }
        // }
        // document.addEventListener('keydown', onKeyDown, false);




    }

    const switchView = (viewmode) => {
        console.log('viewmode>>', viewmode)
        const camera = cameraRef.current;
        // const targetPosition = new THREE.Vector3();
        const targetPosition = new THREE.Vector3(0, 0, 0); // Origin


        switch (viewmode) {
            case 'top':
                camera.position.set(0, 10, 0); // Above at 90 degrees
                camera.lookAt(targetPosition);
                // camera.up.set(0, 0, -1); // Set top view orientation
                break;
            case 'front':

                camera.position.set(0, 0, 10);
                camera.lookAt(targetPosition);
                camera.up.set(0, 1, 0); // Reset up vector
                break;
            case 'back':
                camera.position.set(0, 0, -10);
                camera.lookAt(targetPosition);
                camera.up.set(0, 1, 0); // Reset up vector
                break;
            case 'left':
                camera.position.set(-10, 0, 0);
                camera.lookAt(targetPosition);
                camera.up.set(0, 1, 0); // Reset up vector
                break;
            case 'right':
                camera.position.set(10, 0, 0);
                camera.lookAt(targetPosition);
                camera.up.set(0, 1,); // Reset up vector
                break;
            default:
                console.log('Unknown view:', viewmode);
                return;
        }

        camera.lookAt(targetPosition);


    }
    const cameraMode = (mode) => {

        const targetPosition = new THREE.Vector3(0, 0, 0); // Origin


        console.log('mode>>', mode)

        if (mode == "perspective") {


            cameraRef.current = cameraPersRef.current
            cameraRef.current.position.set(0, 20, 0); // Above at 90 degrees
            cameraRef.current.lookAt(targetPosition);
            orbitRef.current.object = cameraRef.current
            // cameraRef.current.up.set(0, 0, -1);
            // orbitRef.current.target.set(targetPosition.x/, targetPosition.y, targetPosition.z); // Ensure controls target is correct
            orbitRef.current.update();

            renderer.current.shadowMap.enabled = true; // Enable shadows
            sceneRef.current.traverse((object) => {
                if (object.isMesh) {
                    object.castShadow = true;
                    object.receiveShadow = true;
                }
            });


        }
        else if (mode == "orthographic") {

            cameraRef.current = cameraOrthoRef.current
            cameraRef.current.position.set(0, 20, 0); // Above at 90 degrees
            cameraRef.current.lookAt(targetPosition);
            // cameraRef.current.up.set(0, 0, -1);
            orbitRef.current.object = cameraRef.current
            // orbitRef.current.target.set(targetPosition.x, targetPosition.y, targetPosition.z); // Ensure controls target is correct
            orbitRef.current.update();
            renderer.current.shadowMap.enabled = false; // Disable shadows
            sceneRef.current.traverse((object) => {
                if (object.isMesh) {
                    object.castShadow = false;
                    object.receiveShadow = false;
                }
            });



        }

        // if (mode === 'perspective' && cameraType !== 'perspective') {
        //     perspectiveCamera.updateProjectionMatrix();
        //     setCurrentCamera(perspectiveCamera);
        //     setCameraType('perspective');

        // } else if (mode === 'orthographic' && cameraType !== 'orthographic') {
        //     orthographicCamera.updateProjectionMatrix();
        //     setCurrentCamera(orthographicCamera);
        //     setCameraType('orthographic');

        // }

    }
    window.onresize = function () {

        console.log('resizing .....', cameraRef.current.aspect)
        cameraRef.current.aspect = canvasRef.current.clientWidth / canvasRef.current.clientHeight;
        cameraRef.current.updateProjectionMatrix();
        renderer.current.setSize(canvasRef.current.clientWidth, canvasRef.current.clientHeight);
        firstPersonControl.current.handleResize();

    };

    useEffect(() => {
        console.log('useefect')
        if (firstLoad.current === true) {
            init()
            canvasRef.current.addEventListener('click', onMouseClick);
            // canvasRef.current.addEventListener('mousedown', onMouseDown);
            canvasRef.current.addEventListener('dblclick', onDoubleClick, false);


        }
        firstLoad.current = false

        // Cleanup function to remove the event listener
        return () => {
            if (canvasRef.current) {
                canvasRef.current.removeEventListener('click', onMouseClick); // Clean up the listener on unmount
            }
            if (dragControlRef.current) {
                dragControlRef.current.removeEventListener('dragstart', handleDragStart);
                dragControlRef.current.removeEventListener('drag', handleDrag);
                dragControlRef.current.removeEventListener('dragend', handleDragEnd);
                dragControlRef.current.dispose(); // Dispose of DragControls instance if necessary
            }
        };

    }, [])

    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleCategorySelect = (categoryName) => {

        console.log('categoryName', categoryName)

        console.log('productData', productData)

        const category = productData[categoryName];
        if (category) {
            setSelectedCategory(category.subcategories); // Pass subcategories to ProductPanel
        }
    };

    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleProductSelect = (product) => {
        setSelectedProduct(product); // Update the selected product
        console.log('selected product in canvas', product.glb)
        addModel(product.glb)
    };


    useEffect(() => {

        console.log('isloadmodel', modelRef.current)
        if (isModelLoaded) {
            const test = modelRef.current.children.map(el => {
                if (el.type === "Group") {
                    console.log('groups to send', el)
                    return el
                }
            })
            setSectionGroupsChange(test)
        }

    }, [isModelLoaded])



    return (
        <div style={{ display: 'flex', height: '100vh', width: '100%', position: 'relative' }}>

              {/* Loading overlay - only displayed when loading */}
        {isLoading && (
            <div style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 10,
            }}>
                <LoadingProgress progress={loadingProgress} />
            </div>
        )}

        
            <Panel style={{ zIndex: -1 }} resetScene={resetScene} firstPersonMode={firstPersonMode}
                selectMode={selectMode} orbitMode={orbitMode} deleteSelected={deleteSelected} addModel={addModel}
                transformMode={transformMode} dragMode={dragMode} loadModel={loadModel}
                resetCamera={resetCamera} cameraMode={cameraMode} changeTexture={changeTexture}
                switchView={switchView} />

            <CategoryPanel onSelect={handleCategorySelect} />



            {selectedCategory && <ProductPanel onProductSelect={handleProductSelect} subcategories={selectedCategory}
            />}


            <div ref={canvasRef} style={{ backgroundColor: '#BBE9FF', width: '100%', height: '100vh', zIndex: 1 }}></div>
            <SidebarMenu style={{ zIndex: 0 }} onCheckedItems={onCheckedItems} sectionGroupsChange={sectionGroupsChange}
                onItemSelect={onGroupSelect} onTextureRepeat={onTextureRepeat} onTexturePosition={onTexturePosition}
                onTextureRotate={onTextureRotate} onTextureScale={onTextureScale} onImageSelect={onImageSelect} />

        </div>
    );


};

export default Canvas;