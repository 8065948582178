import React, { useState } from 'react';
import { FaBath, FaUtensils, FaCouch, FaThLarge,FaWindowMaximize } from "react-icons/fa"; 


const categories = [
    { name: "Bath", icon: <FaBath size={25} /> },
    { name: "Kitchen", icon: <FaUtensils size={25} /> },
    { name: "Furniture", icon: <FaCouch size={25} /> },
    { name: "Tile", icon: <FaThLarge size={25} /> },
    { name: "Window", icon: <FaWindowMaximize size={25} /> },
  ];

const CategoryPanel = ({ onSelect }) => {

   

    return (
        <div style={styles.container}>
          <h6 style={styles.header}>Category</h6>
          <div style={styles.list}>
            {categories.map((category) => (
              <button
                key={category.name}
                style={styles.button}
                onClick={() => onSelect(category.name)}
              >
                <div style={styles.icon}>{category.icon}</div>
                <span style={styles.text}>{category.name}</span>
              </button>
            ))}
          </div>
        </div>
      );
};

const styles = {
    container: {
      width: "100px",
      borderRight: "1px solid #ccc",
      padding: "10px",
      backgroundColor :"#4b4b4b"
    },
    header: {
      textAlign: "center",
      marginBottom: "10px",
    },
    list: {
      display: "flex",
      flexDirection: "column", // دکمه‌ها را در یک ستون نمایش می‌دهد
      gap: "10px", // فاصله بین دکمه‌ها
    },
    button: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "10px",
      backgroundColor: "#f5f5f5",
      border: "1px solid #ddd",
      borderRadius: "8px",
      cursor: "pointer",
      transition: "0.3s",
      width: "80%", // دکمه تمام عرض پنل را می‌گیرد
    },
    icon: {
      marginBottom: "2px",
    },
    text: {
      fontSize: "10px",
      fontWeight: "bold",
    },
  };
  

export default CategoryPanel;
