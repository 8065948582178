

import React, { useState } from "react";

const ProductPanel = ({ subcategories,onProductSelect }) => {
    console.log('subcategories ProductPanel',subcategories)

  const [selectedSubcategory, setSelectedSubcategory] = useState(
    Object.keys(subcategories)[0]
  );


  const handleSubcategoryClick = (subcategory) => {
    console.log('subcategory',subcategory)
    setSelectedSubcategory(subcategory);
  };

  const products = subcategories[selectedSubcategory];

  const handleProductClick = (product) => {
    // Call the callback function to send the product data to the canvas
    onProductSelect(product);
  };


  return (
    <div style={styles.container}>

      {/* Horizontal Scroll Menu */}
      <div style={styles.scrollMenu}>
        {Object.keys(subcategories).map((subcategory) => (
          <button
            key={subcategory}
            onClick={() => handleSubcategoryClick(subcategory)}
            style={styles.subcategoryButton}
          >
            {subcategory}
          </button>
        ))}
      </div>

      {/* Product List */}
      <div style={styles.productList}>
        {subcategories[selectedSubcategory].map((product) => (
          <div 
          key={product.name} 
          style={styles.productCard}
          onClick={() => handleProductClick(product)} 

          >
            <img src={product.image} alt={product.name} style={styles.image} />
            <h4 style={styles.productName}>{product.name}</h4>
            <p style={styles.productPrice}>Price: ${product.price}</p>
          </div>
        ))}
      </div>
    </div>
  );
};



const styles = {
  container: {
    width: "100%",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "1px",
    height: "100vh",
    backgroundColor:'#3c3c3c'
  },
  scrollMenu: {
    display: "flex",
    overflowX: "auto",
    gap: "10px",
    marginBottom: "10px",
    paddingBottom: "5px",
    borderBottom: "1px solid #ddd", // Optional: Visual separation
  },
  subcategoryButton: {
    padding: "10px 20px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    backgroundColor: "#f5f5f5",
    cursor: "pointer",
    whiteSpace: "nowrap",
    flexShrink: 0, // Ensure buttons don't shrink in flex container
  },
  productList: {
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    justifyContent: "center", // Adjust alignment if needed
    overflowY: "auto", // Ensure vertical scroll if needed
    flex: 1, 
    minHeight: "280px",
  },
  productCard: {
    width: "150px",
    height: "150px", // Fixed height for uniformity
    border: "1px solid #ddd",
    borderRadius: "8px",
    padding: "5px",
    textAlign: "center",
    backgroundColor: "#fff",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "5px",
  },


  image: {
    width: "100%",
    height: "80px",
    objectFit: "cover",
    marginBottom: "5px",
    borderRadius: "5px",
  },
  productName: {
    color: "#888",
    fontSize: "14px",
    margin: "5px 0",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  productPrice: {
    fontSize: "12px",
    color: "#888",
    margin: "0",
  },
};

export default ProductPanel;




