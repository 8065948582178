// LoadingProgress.js
import React from 'react';
import  './loading.css'

const LoadingProgress = ({ progress }) => {

   
    return (
        <div className="progress-container">
            <div className="progress-bar" style={{ width: `${progress}%` }}>
                {progress}%
            </div>
        </div>
    );
};

export default LoadingProgress;



